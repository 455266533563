<template>
  <div style=" margin: -15px -15px;">
    <a-spin :spinning="spinning">
      <div class="live-top">
        <div class="live-top-input">
          <div>年份:</div>
          <a-date-picker
              v-model="year"
              mode="year"
              v-decorator="['year']"
              placeholder="请输入"
              format="YYYY"
              class="input"
              :open="yearShowOne"
              @openChange="openChangeOne"
              @panelChange="panelChangeOne"/>
        </div>
        <div class="live-top-input" style="width: 20%">
          <div>标题:</div>
          <a-input
              @pressEnter="seachBtn"
              placeholder="请输入"
              style="width: 150px;
              margin-left: 5px"
              v-model="titleVal"/>
        </div>
        <div class="live-top-input" style="width: 20%">
          <a-button type="primary" @click="seachBtn">查询</a-button>
          <a-button style="margin-left: 10px" @click="restBtn">重置</a-button>
        </div>
      </div>
      <a-tabs v-model="tableType" @change="callback">
        <a-tab-pane key="1" tab="直播会议">
          <a-table
              style="width: 98%;margin: auto"
              :rowKey='record=>record.id'
              :pagination="false"
              :columns="columns"
              :data-source="dataList">
         <span slot="titles" slot-scope="text, record">
           <a-tooltip>
             <template slot="title">
               {{text}}
             </template>
            <span>{{text}}</span>
           </a-tooltip>
         </span>
            <span slot="liveMettingName" slot-scope="text, record">
           <a-tooltip>
             <template slot="title">
               {{text}}
             </template>
            <span>{{text}}</span>
           </a-tooltip>
         </span>
            <span slot="system" slot-scope="text, record">
           <a @click="openMadel(record)">评分</a>
         </span>
            <span slot="scoreSale" slot-scope="text, record">
              {{record.scoreSale + record.scoreManager}}
            </span>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="2" tab="医学会议">
          <a-table
              style="width: 98%;margin: auto"
              :rowKey='record=>record.id'
              :pagination="false"
              :columns="columnsMedicine"
              :data-source="MedicineList">
            <span slot="titles" slot-scope="text, record">
              <a-tooltip>
                <template slot="title">
                  {{text}}
                </template>
                <span>{{text}}</span>
              </a-tooltip>
            </span>
            <!--            <span slot="numberCC" slot-scope="text, record">-->
            <!--              <span v-if="record.liveRating && record.NonLiveRatings">{{text}}</span>-->
            <!--              <span @click="statisticsOpen" v-else style="color:#45a5e6;cursor: pointer">待统计</span>-->
            <!--            </span>-->
            <span slot="liveRating" slot-scope="text, record">
              <a @click="openLive(record)" style="color: #45A5E6">评分</a>
            </span>
            <span slot="scoreSale" slot-scope="text, record">
              {{record.scoreSale + record.scoreManager}}
            </span>
            <span slot="NonLiveRatings" slot-scope="text, record">
              <a @click="openNoLive(record)" style="color: #45A5E6">评分</a>
            </span>
          </a-table>
        </a-tab-pane>
      </a-tabs>

      <div style="width: 100%">
        <div style="float: right;margin-right: 20px;margin-top: 15px;margin-bottom: 15px">
          <a-pagination
              show-quick-jumper
              :current="page_no"
              :total="total"
              @change="paginationChange" />
        </div>
      </div>

      <!--------------------------------------对话框------------------------>
      <a-drawer
          :get-container="false"
          @close="add_cancel"
          :width="1300"
          :body-style="{ paddingBottom: '80px' }"
          :visible="visible"
          title="直播评分">
        <a-spin :spinning="spinningScore">
        <p>所属会议：{{liveRatingList.liveMettingName}}</p>
        <p>会议标题：{{liveRatingList.title}}</p>
        <p>会议时间：{{liveRatingList.liveDate}}</p>
        <p>运营负责人：{{liveRatingList.leaderOperate}}</p>
        <div style="width: 100%;margin: auto;display: flex;justify-content: space-between;margin-bottom: 10px">
          <div><span style="color: red">*</span> 直播评分</div>
          <div><a-button @click="clickZero">一键归零</a-button></div>
        </div>
        <a-table
            bordered
            style="width: 100%;margin: auto"
            :rowKey='record=>record.id'
            :pagination="false"
            :columns="columnsModel"
            :data-source="dataModel">
          <template slot="scoreAfter" slot-scope="text, record">
            <div class="numberInput">
              <div class="left"
                   :style="{backgroundColor:(record.score *1 - (record.scoreSale *1 + record.scoreManager *1) > 0 && !(record.score *1 - (record.scoreSale *1 + record.scoreManager *1) < 1)) ? '':'#F0F2F5'}"
                   @click="subtractBtn(record,dataModel)">-</div>
              <div class="mid">
                <a-input
                    @pressEnter="pressScoreEnter(record.id,record)"
                    @blur="pressScoreEnter(record.id,record)"
                    @change="e => inputScoreChange(e.target.value, record.id, 'scoreAfter',record)"
                    class="input"
                    v-model="text"
                /></div>
<!--              <div class="mid">{{text - (record.scoreManager + record.scoreSale)}}分</div>-->
              <div class="right"
                   :style="{backgroundColor:record.scoreSale *1 >= 1 ? '':'#F0F2F5'}"
                   @click="addBtn(record,dataModel)">+</div>
            </div>
          </template>
          <template slot="TotalDemerit" slot-scope="text, record">
            <span :style="{color:(record.scoreManager * 1 + record.scoreSale * 1) ? 'red':''}">{{( record.scoreManager * 1 + record.scoreSale * 1) ? ( record.scoreManager * 1 + record.scoreSale * 1) : 0 }}</span>
          </template>
          <template slot="scoreManager" slot-scope="text, record">
            <span :style="{color:text ? 'red':''}">{{ text ? text : 0 }}</span>
          </template>
          <template slot="scoreSale" slot-scope="text, record">
            <span :style="{color:text ? 'red':''}">{{ text ? text : 0 }}</span>
          </template>
          <template slot="deductManager" slot-scope="text, record">
            <span :style="{color:text ? 'red':''}">{{ text ? text : 0 }}</span>
          </template>

          <template
              v-for="col in ['deductSale']"
              :slot="col"
              slot-scope="text, record, index"
          >
            <div :key="col">
              <a-input
                  v-if="record.editable"
                  style="margin: -5px 0"
                  :id="'input' + record.id"
                  :value="text"
                  @pressEnter="pressEnter(record.id)"
                  @blur="pressEnter(record.id)"
                  @change="e => inputChange(e.target.value, record.id, col)"
              />
              <template v-else>
                <span  @click="() => edit(record.id)" :style="{color:text ? 'red':''}">{{ text ? text : '无' }}</span>
              </template>
            </div>
          </template>
          <template slot="footer" slot-scope="currentPageData">
            最终总得分：{{totalScore?totalScore.toFixed(1):0}} ， 被扣除分数：{{pointsDeducted?pointsDeducted.toFixed(1):0}}
          </template>
        </a-table>
        </a-spin>
        <div
            :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
        >
          <a-button  @click="add_cancel">
            取消
          </a-button>
          <a-button style="margin-left: 10px" type="primary" @click="add_content_list_Ok">
            确认
          </a-button>
        </div>
      </a-drawer>
      <a-drawer
          :get-container="false"
          @close="live_cancel"
          :width="1400"
          :body-style="{ paddingBottom: '80px' }"
          :visible="visibleLive"
          title="直播分数统计">
        <a-spin :spinning="spinningLive">
        <p>所属会议：{{liveRatingList.liveMettingName}}</p>
        <div style="width: 100%;margin: auto;display: flex;justify-content: space-between;margin-bottom: 10px">
          <div><span style="color: red">*</span> 直播评分</div>
          <div><a-button @click="clickZero">一键归零</a-button></div>
        </div>
<!--        <a-radio-group v-model="liveTimeValue" @change="liveTimeChange">-->
<!--          <a-radio-button :value="time" v-for="time in liveTimeList" :key="time">-->
<!--            {{time}}-->
<!--          </a-radio-button>-->
<!--        </a-radio-group>-->
          <span v-for="time in liveTimeList" :key="time">
            <a-button style="margin-right: 10px;margin-bottom: 10px" :type="liveTimeValue === time ? 'primary':''">{{time}}</a-button>
          </span>
        <a-table
            bordered
            style="width: 100%;margin: auto"
            :rowKey='record=>record.id'
            :pagination="false"
            :columns="columnsLive"
            :data-source="dataLive">
          <template slot="scoreAfter" slot-scope="text, record">
            <div class="numberInput">
              <div class="left"
                   :style="{backgroundColor:(record.score *1 - (record.scoreSale *1 + record.scoreManager *1) > 0 && !(record.score *1 - (record.scoreSale *1 + record.scoreManager *1) < 1)) ? '':'#F0F2F5'}"
                   @click="subtractBtn(record,dataLive)">-</div>
              <div class="mid">
                <a-input
                    @pressEnter="pressLiveEnter(record.id)"
                    @blur="pressLiveEnter(record.id)"
                    @change="e => inputLiveChange(e.target.value, record.id, 'scoreAfter')"
                    class="input"
                    v-model="text"
                />
              </div>
              <div class="right" :style="{backgroundColor:record.scoreSale *1 >= 1?'':'#F0F2F5'}" @click="addBtn(record,dataLive)">+</div>
            </div>
          </template>
          <template slot="TotalDemerit" slot-scope="text, record">
            <span :style="{color:record.scoreManager *1 + record.scoreSale *1 ? 'red':''}">{{ record.scoreManager *1 + record.scoreSale *1 ? record.scoreManager *1 + record.scoreSale *1 : 0 }}</span>
          </template>
          <template slot="scoreManager" slot-scope="text, record">
            <span :style="{color:text ? 'red':''}">{{ text ? text : 0 }}</span>
          </template>
          <template slot="scoreSale" slot-scope="text, record">
            <span :style="{color:text ? 'red':''}">{{ text ? text : 0 }}</span>
          </template>
          <template slot="deductManager" slot-scope="text, record">
            <span :style="{color:text ? 'red':''}">{{ text ? text : '无' }}</span>
          </template>

          <template
              v-for="col in ['deductSale']"
              :slot="col"
              slot-scope="text, record, index"
          >
            <div :key="col">
              <a-input
                  v-if="record.editable"
                  style="margin: -5px 0"
                  :id="'inputLive' + record.id"
                  :value="text"
                  @pressEnter="pressEnterLive(record.id)"
                  @blur="pressEnterLive(record.id)"
                  @change="e => inputChangeLive(e.target.value, record.id, col)"
              />
              <template v-else>
                <span  @click="() => editLive(record.id)" :style="{color:text ? 'red':''}">{{ text ? text : '无' }}</span>
              </template>
            </div>
          </template>
          <template slot="footer" slot-scope="currentPageData">
            最终总得分：{{totalScore_statistics?totalScore_statistics.toFixed(1):0}} ， 被扣除分数：{{pointsDeducted_statistics?pointsDeducted_statistics.toFixed(1):0}}
          </template>
        </a-table>
          <div style="width: 100%;text-align: right;margin-top: 10px">
            <a-button @click="save" type="primary">
              {{liveTimeList.indexOf(liveTimeValue) !== liveTimeList.length-1 ? '保存并继续':'保存'}}
            </a-button>
          </div>
        </a-spin>
<!--        <div-->
<!--            :style="{-->
<!--          position: 'absolute',-->
<!--          right: 0,-->
<!--          bottom: 0,-->
<!--          width: '100%',-->
<!--          borderTop: '1px solid #e9e9e9',-->
<!--          padding: '10px 16px',-->
<!--          background: '#fff',-->
<!--          textAlign: 'right',-->
<!--          zIndex: 1,-->
<!--        }"-->
<!--        >-->
<!--          <a-button  @click="live_cancel">-->
<!--            取消-->
<!--          </a-button>-->
<!--          <a-button style="margin-left: 10px" type="primary" @click="live_Ok">-->
<!--            确认-->
<!--          </a-button>-->
<!--        </div>-->
      </a-drawer>
      <a-modal
          title="评分扣除"
          :visible="visibleModal"
          @ok="handleOk"
          @cancel="handleCancel"
      >
        <a-textarea v-model="scoreDeduction" placeholder="请输入" :rows="4" />
        <div style="color: red;margin-top: 20px">确认后所有分值将全部归0</div>
      </a-modal>
      <a-drawer
          :get-container="false"
          @close="add_cancel_NoLive"
          :width="1300"
          :body-style="{ paddingBottom: '80px' }"
          :visible="visibleNoLive"
          title="非直播类分数统计">
        <a-spin :spinning="spinningNoLive">
        <a-table
            bordered
            style="width: 100%;margin: auto"
            :rowKey='record=>record.id'
            :pagination="false"
            :columns="columnsNoLive"
            :data-source="dataNoLive">
          <template slot="scoreAfter" slot-scope="text, record">
            <div class="numberInput">
              <div class="left"
                   :style="{backgroundColor:(record.score *1 - (record.scoreSale *1 + record.scoreManager *1) > 0 && !(record.score *1 - (record.scoreSale *1 + record.scoreManager *1) < 1)) ? '':'#F0F2F5'}"
                   @click="subtractBtn(record,dataNoLive)">-</div>
              <div class="mid">
                <a-input
                    @pressEnter="pressNoLiveEnter(record.id)"
                    @blur="pressNoLiveEnter(record.id)"
                    @change="e => inputNoLiveChange(e.target.value, record.id, 'scoreAfter')"
                    class="input"
                    v-model="text"
                />
              </div>
              <!--              <div class="right" v-if="record.DeductionDemerit === 0 || record.DeductionDemerit === null || record.DeductionDemerit === undefined">+</div>-->
              <div class="right"
                   :style="{backgroundColor:record.scoreSale *1 >= 1 ? '':'#F0F2F5'}"
                   @click="addBtn(record,dataNoLive)">+</div>
            </div>
          </template>
          <template slot="TotalDemerit" slot-scope="text, record">
            <span :style="{color:record.scoreManager *1 + record.scoreSale *1 ? 'red':''}">{{ record.scoreManager *1 + record.scoreSale *1 ? record.scoreManager *1 + record.scoreSale *1 : 0 }}</span>
          </template>
          <template slot="scoreManager" slot-scope="text, record">
            <span :style="{color:text ? 'red':''}">{{ text ? text : 0 }}</span>
          </template>
          <template slot="scoreSale" slot-scope="text, record">
            <span :style="{color:text ? 'red':''}">{{ text ? text : 0 }}</span>
          </template>
          <template slot="deductManager" slot-scope="text, record">
            <span :style="{color:text ? 'red':''}">{{ text ? text : '无' }}</span>
          </template>

          <template
              v-for="col in ['deductSale']"
              :slot="col"
              slot-scope="text, record, index"
          >
            <div :key="col">
              <a-input
                  v-if="record.editable"
                  style="margin: -5px 0"
                  :id="'inputNoLive' + record.id"
                  :value="text"
                  @pressEnter="pressEnterNoLive(record.id)"
                  @blur="pressEnterNoLive(record.id)"
                  @change="e => inputChangeNoLive(e.target.value, record.id, col)"
              />
              <template v-else>
                <span  @click="() => editNoLive(record.id)" :style="{color:text ? 'red':''}">{{ text ? text : '无' }}</span>
              </template>
            </div>
          </template>
        </a-table>
        </a-spin>
        <div
            :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
        >
          <a-button  @click="add_cancel_NoLive">
            取消
          </a-button>
          <a-button style="margin-left: 10px" type="primary" @click="add_NoLive_Ok">
            确认
          </a-button>
        </div>

      </a-drawer>
      <!--   统计   -->
      <a-drawer
          :get-container="false"
          @close="statistics_cancel"
          :width="700"
          :body-style="{ paddingBottom: '80px' }"
          :visible="visible_statistics"
          title="直播总场次统计">
        <a-table
            bordered
            style="width: 100%;margin: auto"
            :rowKey='record=>record.id'
            :pagination="false"
            :columns="columnsStatistics"
            :data-source="dataStatistics">
          <template slot="TotalScore" slot-scope="text, record">
            <div class="numberInput">
              <div class="left" @click="subtractStatistics(record)">-</div>
              <div class="mid">{{text}}场</div>
              <div class="right" @click="addStatistics(record)">+</div>
            </div>
          </template>
          <template slot="score" slot-scope="text, record">
            <span :style="{color:text ? 'red':''}">{{ text ? text : 0 }}分</span>
          </template>
        </a-table>
        <div
            :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
        >
          <a-button  @click="statistics_cancel">
            取消
          </a-button>
          <a-button style="margin-left: 10px" type="primary" @click="statistics_Ok">
            确认
          </a-button>
        </div>
      </a-drawer>
    </a-spin>
  </div>
</template>

<script>

import moment from "moment";
import {current_date,} from '@/utils/currentDate'
import {
  clickClear,
  getConventionForRecords, getDateByConventionId, getLiveScoreRecordsByConventionId,
  getLiveScoreRecordsByStreamId,
  getLiveStreamForRecordsZG, getRecordsByIdAndDate, oneClickClear,
  updLiveScoreRecords
} from "@/service/rating";
const columns = [
  {
    title: '标题',
    dataIndex: 'title',
    key: 'title',
    width:'20%',
    ellipsis: true,
    scopedSlots: { customRender: 'titles' },
  },
  {
    title: '所属会议',
    dataIndex: 'liveMettingName',
    key: 'liveMettingName',
    width:'20%',
    ellipsis: true,
    scopedSlots: { customRender: 'liveMettingName' },
  },
  {
    title: '会议时间',
    dataIndex: 'liveDate',
    key: 'liveDate',
    width:'20%',
  },
  {
    title: '业务负责人',
    dataIndex: 'leaderSales',
    key: 'leaderSales',
    // scopedSlots: { customRender: 'businessManager' },
  },
  {
    title: '总扣分',
    dataIndex: 'scoreSale',
    key: 'scoreSale',
    scopedSlots: { customRender: 'scoreSale' },
  },
  {
    title: '运营负责人',
    dataIndex: 'leaderOperate',
    key: 'leaderOperate',
    width:'10%',
  },
  {
    title: '操作',
    dataIndex: 'system',
    key: 'system',
    width:'10%',
    scopedSlots: { customRender: 'system' },
  },
];
const columnsMedicine = [
  {
    title: '所属会议',
    dataIndex: 'title',
    key: 'title',
    width:'20%',
    ellipsis: true,
    scopedSlots: { customRender: 'titles' },
  },
  {
    title: '会议时间',
    dataIndex: 'beginDate',
    key: 'beginDate',
    width:'20%',
    scopedSlots: { customRender: 'beginDate' },
  },
  {
    title: '主负责人',
    dataIndex: 'conventionHead',
    key: 'conventionHead',
    // scopedSlots: { customRender: 'principal' },
  },{
    title: '总扣分',
    dataIndex: 'scoreSale',
    key: 'scoreSale',
    scopedSlots: { customRender: 'scoreSale' },
  },
  {
    title: '直播评分',
    width:'10%',
    scopedSlots: { customRender: 'liveRating' },
  },{
    title: '非直播类评分',
    width:'15%',
    scopedSlots: { customRender: 'NonLiveRatings' },
  },
];
const dataModel = [];
const columnsModel = [
  {
    title: '类型',
    dataIndex: 'largeCategory',
    key: 'largeCategory',
    ellipsis: true,
    customRender (_, row) {
      return {
        children: row.largeCategory,
        attrs: {
          rowSpan: row.largeCategoryRowSpan
        }
      }
    }
  },
  {
    title: '内容',
    dataIndex: 'subclass',
    key: 'subclass',
  },
  {
    title: '得分值（分）',
    dataIndex: 'scoreAfter',
    key: 'scoreAfter',
    scopedSlots: { customRender: 'scoreAfter' },
  },
  {
    title: '总扣分',
    key: 'TotalDemerit',
    scopedSlots: { customRender: 'TotalDemerit' },
  },
  {
    title: '主管扣分',
    dataIndex: 'scoreManager',
    key: 'scoreManager',
    scopedSlots: { customRender: 'scoreManager' },
  },{
    title: '主管扣分理由',
    dataIndex: 'deductManager',
    key: 'deductManager',
    scopedSlots: { customRender: 'deductManager' },
  },{
    title: '业务扣分',
    dataIndex: 'scoreSale',
    key: 'scoreSale',
    scopedSlots: { customRender: 'scoreSale' },
  },{
    title: '业务扣分理由',
    dataIndex: 'deductSale',
    key: 'deductSale',
    scopedSlots: { customRender: 'deductSale' },
  },
];
const dataNoLive = [];
const columnsNoLive = [
  {
    title: '类型',
    dataIndex: 'largeCategory',
    key: 'largeCategory',
    ellipsis: true,
    customRender (_, row) {
      return {
        children: row.largeCategory,
        attrs: {
          rowSpan: row.largeCategoryRowSpan
        }
      }
    }
  },
  {
    title: '内容',
    dataIndex: 'subclass',
    key: 'subclass',
  },
  {
    title: '得分人姓名',
    dataIndex: 'scoreName',
    key: 'scoreName',
  },
  {
    title: '得分值（分）',
    dataIndex: 'scoreAfter',
    key: 'scoreAfter',
    scopedSlots: { customRender: 'scoreAfter' },
  },
  {
    title: '总扣分',
    // dataIndex: 'TotalDemerit',
    key: 'TotalDemerit',
    scopedSlots: { customRender: 'TotalDemerit' },
  },{
    title: '主管扣分',
    dataIndex: 'scoreManager',
    key: 'scoreManager',
    scopedSlots: { customRender: 'scoreManager' },
  },{
    title: '主管扣分理由',
    dataIndex: 'deductManager',
    key: 'deductManager',
    scopedSlots: { customRender: 'deductManager' },
  },{
    title: '业务扣分',
    dataIndex: 'scoreSale',
    key: 'scoreSale',
    scopedSlots: { customRender: 'scoreSale' },
  },{
    title: '业务扣分理由',
    dataIndex: 'deductSale',
    key: 'deductSale',
    scopedSlots: { customRender: 'deductSale' },
  },
];
const dataLive = [];
const columnsLive = [
  {
    title: '类型',
    dataIndex: 'largeCategory',
    key: 'largeCategory',
    ellipsis: true,
    customRender (_, row) {
      return {
        children: row.largeCategory,
        attrs: {
          rowSpan: row.largeCategoryRowSpan
        }
      }
    }
  },
  {
    title: '内容',
    dataIndex: 'subclass',
    key: 'subclass',
  },
  {
    title: '标题',
    dataIndex: 'title',
    key: 'title',
  },{
    title: '负责人',
    dataIndex: 'scoreName',
    key: 'scoreName',
  },
  {
    title: '得分值（分）',
    dataIndex: 'scoreAfter',
    key: 'scoreAfter',
    scopedSlots: { customRender: 'scoreAfter' },
  },
  {
    title: '总扣分',
    // dataIndex: 'TotalDemerit',
    key: 'TotalDemerit',
    scopedSlots: { customRender: 'TotalDemerit' },
  },{
    title: '主管扣分',
    dataIndex: 'scoreManager',
    key: 'scoreManager',
    scopedSlots: { customRender: 'scoreManager' },
  },{
    title: '主管扣分理由',
    dataIndex: 'deductManager',
    key: 'deductManager',
    scopedSlots: { customRender: 'deductManager' },
  },{
    title: '业务扣分',
    dataIndex: 'scoreSale',
    key: 'scoreSale',
    scopedSlots: { customRender: 'scoreSale' },
  },{
    title: '业务扣分理由',
    dataIndex: 'deductSale',
    key: 'deductSale',
    scopedSlots: { customRender: 'deductSale' },
  },
];
export default {
  data(){
    // this.cacheData = dataModel.map(item => ({ ...item }));
    // this.cacheDataNoLive = dataNoLive.map(item => ({ ...item }));
    // this.cacheDataLive = dataLive.map(item => ({ ...item }));
    return{
      cacheData:[],
      cacheDataNoLive:[],
      cacheDataLive:[],
      columnsMedicine,
      year:current_date(),
      tableType:"1",
      titleVal:'',
      visible:false,
      liveRatingId:null,
      liveId:null,
      liveItitleVald:'',

      totalScore:0, // 直播评分 - 最终总得分
      pointsDeducted:0, //直播评分 - 被扣除分数

      totalScore_statistics:0, // 直播分数统计 - 最终总得分
      pointsDeducted_statistics:0, //直播分数统计 - 被扣除分数

      liveRatingList:{ //直播评分顶部信息
        liveMettingName:'',
        title:'',
        liveDate:'',
        leaderOperate:'',
      },
      visibleModal:false,
      scoreDeduction:'',
      visibleNoLive:false,
      yearShowOne:false,
      visibleLive:false,
      visible_statistics:false,
      columnsNoLive,
      dataNoLive,
      columnsModel,
      dataLive,
      columnsLive,
      NoLiveId:null,
      labelCol: { span: 4},
      wrapperCol: { span: 14 },
      dataList:[],
      MedicineList:[],
      dataModel,
      columns,
      page_no:1,
      page_size:10,
      total:0,
      spinning:false,
      radioValue:'0',
      editingKey: '',
      liveTimeValue:null,
      liveTimeValueIndex:0,
      liveTimeList:[],
      columnsStatistics: [
        {
          title: '得分内容',
          dataIndex: 'name',
          key: 'name',
          ellipsis: true,
        },
        {
          title: '场次',
          dataIndex: 'TotalScore',
          key: 'TotalScore',
          scopedSlots: { customRender: 'TotalScore' },
        },
        {
          title: '得分',
          dataIndex: 'score',
          key: 'score',
          scopedSlots: { customRender: 'score' },
        },
      ],
      dataStatistics : [
        {
          id:1,
          name:'王利康',
          TotalScore:11,
          score:1,
        },
        {
          id:2,
          name:'冯明霞',
          TotalScore:11,
          score:1,
        },
      ],
      spinningNoLive:false,
      spinningLive:false,
      spinningScore:false,
      user_name:'',
    }
  },
  created() {
    const user_info = window.localStorage.getItem("user_info");
    this.user_name =  JSON.parse(user_info).user_name
    this.getLiveStreamList()
  },
  methods:{
    //获取直播列表
    async getLiveStreamList() {
      this.spinning = true;
      let data = {
        type:'XS', //主管传ZG  销售传XS
        isPF:this.radioValue === '0'?0:1,
        year:this.year.substr(0,4),
        title:this.titleVal,
        name:this.user_name,
        pageNo:this.page_no,
        pageSize:this.page_size,
      }
      const response = await getLiveStreamForRecordsZG(data)
      if(response.code === 0){
        this.dataList = response.data
        this.total = response.count
      }else {
        this.$message.warning(response.message)
      }
      this.spinning = false;
    },
    //获取医学列表
    async getConventionList() {
      this.spinning = true;
      let data = {
        type:'XS', //主管传ZG  销售传XS
        isPF:this.radioValue === '0'?0:1,
        year:this.year.substr(0,4),
        title:this.titleVal,
        name:this.user_name,
        pageNo:this.page_no,
        pageSize:this.page_size,
      }
      const response = await getConventionForRecords(data)
      if(response.code === 0){
        this.MedicineList = response.data
        this.total = response.count
      }else {
        this.$message.warning(response.message)
      }
      this.spinning = false;
    },
    // 合并单元格
    rowSpan (key, data) {
      const arr = data
          .reduce((result, item) => {
            if (result.indexOf(item[key]) < 0) {
              result.push(item[key])
            }
            return result
          }, [])
          .reduce((result, keys) => {
            const children = data.filter(item => item[key] === keys)
            result = result.concat(
                children.map((item, index) => ({
                  ...item,
                  [`${key}RowSpan`]: index === 0 ? children.length : 0
                }))
            )
            return result
          }, [])
      return arr
    },
    // 表格合并
    mergeRowCell (data) {
      let tableData = this.rowSpan('largeCategory', data)
      // tableData = this.rowSpan('content', tableData)
      this.dataModel = tableData
    },
    // 表格合并
    mergeRowCellNoLive (data) {
      let tableData = this.rowSpan('largeCategory', data)
      // tableData = this.rowSpan('content', tableData)
      this.dataNoLive = tableData
    },
    mergeRowCellLive (data) {
      let tableData = this.rowSpan('largeCategory', data)
      // tableData = this.rowSpan('content', tableData)
      this.dataLive = tableData
    },
    radioChange(){
      if(this.tableType === '1'){
        this.getLiveStreamList();
      }else if(this.tableType === '2') {
        this.getConventionList();
      }
    },
    paginationChange(page_no,page_size){
      this.page_no = page_no
      this.page_size = page_size
      if(this.tableType === '1'){
        this.getLiveStreamList();
      }else if(this.tableType === '2') {
        this.getConventionList();
      }
    },
    // 弹出日历和关闭日历的回调
    openChangeOne(status) {
      if (status) {
        this.yearShowOne = true;
      }else {
        this.yearShowOne = false;
      }
    },
    // 得到年份选择器的值
    panelChangeOne(value) {
      this.yearShowOne = false;
      this.year = value;
      this.page_no = 1
      if(this.year){
        this.year = moment(this.year).format('YYYY')
      }
      if(this.tableType === '1'){
        this.getLiveStreamList();
      }else if(this.tableType === '2') {
        this.getConventionList();
      }
    },
    seachBtn(){
      if(this.tableType === '1'){
        this.getLiveStreamList();
      }else if(this.tableType === '2') {
        this.getConventionList();
      }
    },
    restBtn(){
      this.year = current_date();
      this.titleVal = '';
      this.seachBtn();
    },
    async add_cancel() {
      //  关闭对话框
      await this.getLiveStreamList();
      this.visible = false;
    },
    async add_content_list_Ok() {
      let data = this.dataModel;
      let list = [];
      let isSub = true;
      //判断每一项的主管扣分有没有输入扣分理由
      for (let i=0;i<data.length;i++){

        if(data[i].scoreSale && !data[i].deductSale || !data[i].scoreSale && data[i].deductSale){ //当业务扣分为true,业务扣分原因为false
          isSub = false;
          let num = i+1;
          this.$message.warning('第'+num+'行的业务扣分或业务扣分理由为空，请先输入扣分理由！')
        }else {
          //1.获取本地存储内容
          const user_info = window.localStorage.getItem("user_info");
          const user_id =  JSON.parse(user_info).id
          let arry = {
            id:data[i].id,
            saleId:user_id,
            scoreSale:data[i].scoreSale * 1,
            deductSale:data[i].deductSale,
          }
          list.push(arry)
        }
      }
      if(isSub){
        this.spinningScore = true;
        const response = await updLiveScoreRecords(list)
        if(response.code === 200){
          this.$message.success("修改成功~")
          await this.getLiveRatingList(this.liveRatingId)
        }
      }
    },

    handleOk(){
      this.scoreDeductionSub();
      this.scoreDeduction = '';
      this.visibleModal = false;
    },
    //评分扣除
    scoreDeductionSub() {
      if( this.scoreDeduction){
        let data = [];
        //判断是那个data
        if(this.visible){ //直播评分
          for (let i=0;i<this.dataModel.length;i++){
            data.push(this.dataModel[i].id)
          }
          this.scoreSub(data)
        }
        else if(this.visibleLive){ //直播分数统计
          //一键清空评分
          this.zeroFun(this.liveId)
        }
      }else {
        this.$message.warning("请先输入扣分内容~")
      }
    },
    async scoreSub(data) {
      const response = await oneClickClear('XS', this.scoreDeduction, data)
      if (response.code === 200) {
        this.$message.success("扣分成功~")
        //判断是那个data
        if(this.visible){
          await this.getLiveRatingList(this.liveRatingId)
        }
      }
    },
    //一键清空
    async zeroFun(id) {
      this.spinningLive = true;
      const response = await clickClear('XS', this.scoreDeduction,id)
      if (response.code === 200) {
        await this.getLiveList(id)
      }
      this.spinningLive = false;
    },
    handleCancel(){
      this.scoreDeduction = '';
      this.visibleModal = false;
    },
    clickZero(){
      this.visibleModal = true
    },

    subtractBtn(record,data){ //减分
      const newData = [...data];
      const target = newData.find(item => record.id === item.id);
      if(target){
          if (target.score *1 - (target.scoreSale *1 + target.scoreManager *1) > 0 && !(target.score *1 - (target.scoreSale *1 + target.scoreManager *1) < 1)) { //得出总扣分/主管扣分 - 总扣分必须小于等于得分值
            target.scoreSale++ //业务扣分
            target.scoreAfter-- //最终得分
            target.TotalDemerit = target.scoreSale + target.scoreManager; //总扣分 =  主管扣分 + 业务扣分
            data = newData; //保存修改的数据
          }
      }
      if(this.visible){
        //获取总得分和总扣除分数
        this.getTotal(data)
      }else if(this.visibleLive){
        this.getLiveTotal(data)
      }
    },
    addBtn(record,data){ //加分
      for (let i=0;i<data.length;i++){
        if(record.id === data[i].id && data[i].scoreSale *1 >= 1){
          data[i].scoreAfter++ //最终得分
          data[i].scoreSale-- //业务扣分
          data[i].TotalDemerit = data[i].scoreSale + data[i].scoreManager; //总扣分
        }
      }
      if(this.visible){
        //获取总得分和总扣除分数
        this.getTotal(data)
      }else if(this.visibleLive){
        this.getLiveTotal(data)
      }
    },
    edit(id) {
      const newData = [...this.dataModel];
      const target = newData.find(item => id === item.id);
      this.editingKey = id;
      if (target) {
        target.editable = true;
        this.dataModel = newData;
      }
      this.$nextTick(()=>{ //自动聚焦
        document.getElementById('input'+id).focus();
      })
    },
    editLive(id) {
      const newData = [...this.dataLive];
      const target = newData.find(item => id === item.id);
      this.editingKey = id;
      if (target) {
        target.editable = true;
        this.dataLive = newData;
      }
      this.$nextTick(()=>{ //自动聚焦
        document.getElementById('inputLive'+id).focus();
      })
    },
    editNoLive(id) {
      const newData = [...this.dataNoLive];
      const target = newData.find(item => id === item.id);
      this.editingKey = id;
      if (target) {
        target.editable = true;
        this.dataNoLive = newData;
      }
      this.$nextTick(()=>{ //自动聚焦
        document.getElementById('inputNoLive'+id).focus();
      })
    },
    inputChange(value, id, column){
      const newData = [...this.dataModel];
      const target = newData.find(item => id === item.id);
      if (target) {
        target[column] = value;
        this.dataModel = newData;
      }
    },
    inputNoLiveChange(value, id, column){
      const newData = [...this.dataNoLive];
      const target = newData.find(item => id === item.id);
      if (target) {
        if(value <= target.score - target.scoreManager){
          target[column] = value;
          target.scoreSale = (target.score - (target.scoreAfter * 1) - target.scoreManager).toFixed(1) *1
          this.dataNoLive = newData;
        }else {
          this.$message.warning("请规范扣分~")
        }
      }
      this.getTotal(newData)
    },
    pressNoLiveEnter(id){ //回车操作，保存编辑内容
      const newData = [...this.dataNoLive];
      const newCacheData = [...this.cacheDataNoLive];
      const target = newData.find(item => id === item.id);
      const targetCache = newCacheData.find(item => id === item.id);
      if (target && targetCache) {
        // delete target.editable;
        this.dataNoLive = newData;
        Object.assign(targetCache, target);
        this.cacheDataNoLive = newCacheData;
      }
      this.editingKey = '';
    },
    inputLiveChange(value, id, column){
      const newData = [...this.dataLive];
      const target = newData.find(item => id === item.id);
      if (target) {
        if(value <= target.score - target.scoreManager){
          target[column] = value;
          target.scoreSale = (target.score - (target.scoreAfter * 1) - target.scoreManager *1).toFixed(1) *1
          this.dataLive = newData;
        }else {
          this.$message.warning("请规范扣分~")
        }
      }
      this.getTotal(newData)
    },
    pressLiveEnter(id){ //回车操作，保存编辑内容
      const newData = [...this.dataLive];
      const newCacheData = [...this.cacheDataLive];
      const target = newData.find(item => id === item.id);
      const targetCache = newCacheData.find(item => id === item.id);
      if (target && targetCache) {
        // delete target.editable;
        this.dataLive = newData;
        Object.assign(targetCache, target);
        this.cacheDataLive = newCacheData;
      }
      this.editingKey = '';
    },
    inputScoreChange(value, id, column){
      const newData = [...this.dataModel];
      const target = newData.find(item => id === item.id);
      if (target) {
        if(value <= target.score - target.scoreManager){
          target[column] = value;
          target.scoreSale = (target.score - (target.scoreAfter * 1) - target.scoreManager).toFixed(1) *1
          this.dataModel = newData;
        }else {
          this.$message.warning("请规范扣分~")
        }
      }
      this.getTotal(newData)
    },
    pressScoreEnter(id,row){ //回车操作，保存编辑内容
      const newData = [...this.dataModel];
      const newCacheData = [...this.cacheData];
      const target = newData.find(item => id === item.id);
      const targetCache = newCacheData.find(item => id === item.id);
      if (target && targetCache) {
        // delete target.editable;
        this.dataModel = newData;
        Object.assign(targetCache, target);
        this.cacheData = newCacheData;
      }
      this.editingKey = '';
      // //编辑后主管扣分 = 主管扣分 + （原始得分 - 输入的最终得分）
      // row.scoreManager = row.scoreManager + (row.score - row.scoreAfter)
    },
    inputChangeLive(value, id, column){
      const newData = [...this.dataLive];
      const target = newData.find(item => id === item.id);
      if (target) {
        target[column] = value;
        this.dataLive = newData;
      }
    },
    inputChangeNoLive(value, id, column){
      const newData = [...this.dataNoLive];
      const target = newData.find(item => id === item.id);
      if (target) {
        target[column] = value;
        this.dataNoLive = newData;
      }
    },
    pressEnter(id){ //回车操作，保存编辑内容
      const newData = [...this.dataModel];
      const newCacheData = [...this.cacheData];
      const target = newData.find(item => id === item.id);
      const targetCache = newCacheData.find(item => id === item.id);
      if (target && targetCache) {
        delete target.editable;
        this.dataModel = newData;
        Object.assign(targetCache, target);
        this.cacheData = newCacheData;
      }
      this.editingKey = '';
    },
    pressEnterLive(id){ //回车操作，保存编辑内容
      const newData = [...this.dataLive];
      const newCacheData = [...this.cacheDataLive];
      const target = newData.find(item => id === item.id);
      const targetCache = newCacheData.find(item => id === item.id);
      if (target && targetCache) {
        delete target.editable;
        this.dataLive = newData;
        Object.assign(targetCache, target);
        this.cacheDataLive = newCacheData;
      }
      this.editingKey = '';
    },
    pressEnterNoLive(id){ //回车操作，保存编辑内容
      const newData = [...this.dataNoLive];
      const newCacheData = [...this.cacheDataNoLive];
      const target = newData.find(item => id === item.id);
      const targetCache = newCacheData.find(item => id === item.id);
      if (target && targetCache) {
        delete target.editable;
        this.dataNoLive = newData;
        Object.assign(targetCache, target);
        this.cacheDataNoLive = newCacheData;
      }
      this.editingKey = '';
    },
    liveTimeChange(){
      this.getLiveList(this.liveId)
    },

    // inputBlur(id){ //取消输入框操作，回复原来的数据
    //   const newData = [...this.dataModel];
    //   const target = newData.find(item => id === item.id);
    //   this.editingKey = '';
    //   if (target) {
    //     Object.assign(target, this.cacheData.find(item => id === item.id));
    //     delete target.editable;
    //     this.dataModel = newData;
    //   }
    // },
    openMadel(row){
      this.liveRatingList = {
        liveMettingName:row.liveMettingName,
        title:row.title,
        liveDate:row.liveDate,
        leaderOperate:row.leaderOperate,
      };
      this.liveRatingId = row.id
      this.getLiveRatingList(row.id)
      this.visible = true
    },
    //获取直播评分列表
    async getLiveRatingList(streamId) {
      this.spinningScore = true;
      const response = await getLiveScoreRecordsByStreamId(streamId)
      if(response.code === 0){
        this.dataModel = response.data
        this.cacheData = this.dataModel.map(item => ({ ...item }));
        this.getTotal(response.data)
        await this.mergeRowCell(this.dataModel)
      }
      this.spinningScore = false;
    },
    //获取总得分和总扣除分数
    getTotal(data){
      this.totalScore = 0;
      this.pointsDeducted = 0;
      //获取总得分
      for (let i=0;i<data.length;i++){
        // this.totalScore = this.totalScore + data[i].score
        this.totalScore = this.totalScore + data[i].scoreAfter * 1
        this.pointsDeducted = this.pointsDeducted + data[i].scoreManager * 1 + data[i].scoreSale * 1

      }
    },

    callback(val){
      this.page_no = 1;
      if(val === '1'){
        this.getLiveStreamList();
      }else if(val === '2') {
        this.getConventionList();
      }
    },
    add_cancel_NoLive(){
      this.getConventionList();
      this.visibleNoLive = false;
    },
    async add_NoLive_Ok() {
      let data = this.dataNoLive;
      let list = [];
      let isSub = true;
      //判断每一项的主管扣分有没有输入扣分理由
      for (let i = 0; i < data.length; i++) {

        if (data[i].scoreSale && !data[i].deductSale || !data[i].scoreSale && data[i].deductSale) { //当主管扣分为true,扣分原因为false
          isSub = false;
          let num = i + 1;
          this.$message.warning('第' + num + '行的业务扣分或业务扣分理由为空，请先输入扣分理由！')
        } else {
          //1.获取本地存储内容
          const user_info = window.localStorage.getItem("user_info");
          const user_id = JSON.parse(user_info).id
          let arry = {
            id: data[i].id,
            saleId: user_id,
            scoreSale: data[i].scoreSale *1,
            deductSale: data[i].deductSale,
          }
          list.push(arry)
        }
      }
      if (isSub) {
        this.spinningNoLive = true;
        const response = await updLiveScoreRecords(list)
        if (response.code === 200) {
          this.$message.success("修改成功~")
          await this.getLiveScoreRecordsByConvention(this.NoLiveId)
        }
      }
    },
    async openNoLive(row) {
      this.NoLiveId = row.id
      await this.getLiveScoreRecordsByConvention(row.id)
      this.visibleNoLive = true;
    },
    //获取非直播类分数统计
    async getLiveScoreRecordsByConvention(conventionId) {
      this.spinningNoLive = true;
      const response = await getLiveScoreRecordsByConventionId(conventionId)
      if(response.code === 0){
        this.dataNoLive = response.data
        this.cacheDataNoLive = this.dataNoLive.map(item => ({ ...item }));
        this.mergeRowCellNoLive(this.dataNoLive)
      }
      this.spinningNoLive = false;
    },
    async openLive(row) {
      this.liveRatingList = {
        liveMettingName: row.title
      }
      this.liveId = row.id
      await this.getLiveTimeList(row.id)
      await this.getLiveList(row.id)
      this.visibleLive = true;
    },
    //获取医学会议下的直播评分时间
    async getLiveTimeList(conventionId) {
      const response = await getDateByConventionId(conventionId)
      if (response.code === 0) {
        this.liveTimeList = response.data
        this.liveTimeValue = response.data[0]
        if(response.data.length === 0){
          this.$message.info("请先创建直播间！")
        }
      } else {
        this.$message.warning(response.message)
      }
    },
    //获取医学会议下的直播评分列表
    async getLiveList(conventionId) {
      this.spinningLive = true;
      let data = {
        date:this.liveTimeValue,
        conventionId:conventionId,
      }
      const response = await getRecordsByIdAndDate(data)
      if (response.code === 0) {
        this.dataLive = response.data
        this.cacheDataLive = this.dataLive.map(item => ({ ...item }));
        this.getLiveTotal(response.data)
        this.mergeRowCellLive(this.dataLive)
      } else {
        this.$message.warning(response.message)
      }
      this.spinningLive = false;
    },
    //获取总得分和总扣除分数
    getLiveTotal(data){
      this.totalScore_statistics = 0;
      this.pointsDeducted_statistics = 0;
      //获取总得分
      for (let i=0;i<data.length;i++){
        this.totalScore_statistics = this.totalScore_statistics + data[i].scoreAfter * 1
        this.pointsDeducted_statistics = this.pointsDeducted_statistics + data[i].scoreManager * 1 + data[i].scoreSale * 1
      }
    },
    live_cancel(){
      this.getConventionList();
      this.visibleLive = false;
    },
    saveNext(){
      //初始状态为零，点击一次按钮索引加一
      if(this.liveTimeList.indexOf(this.liveTimeValue) === 0){
        this.liveTimeValueIndex = 0;
      }
      if(this.liveTimeValueIndex < this.liveTimeList.indexOf(this.liveTimeValue) || this.liveTimeList.indexOf(this.liveTimeValue) === 0){
        if(this.liveTimeList.length > 1){
          this.liveTimeValueIndex ++;
        }
        this.liveTimeValue = this.liveTimeList[this.liveTimeValueIndex];
        this.getLiveList(this.liveId);
      }
    },
    // async live_Ok() {
    async save() {
      let data = this.dataLive;
      let list = [];
      let isSub = true;
      //判断每一项的主管扣分有没有输入扣分理由
      for (let i = 0; i < data.length; i++) {

        if (data[i].scoreSale && !data[i].deductSale || !data[i].scoreSale && data[i].deductSale) { //当业务扣分为true,业务扣分原因为false
          isSub = false;
          let num = i + 1;
          this.$message.warning('第' + num + '行的业务扣分或业务扣分理由为空，请先输入扣分理由！')
        } else {
          //1.获取本地存储内容
          const user_info = window.localStorage.getItem("user_info");
          const user_id = JSON.parse(user_info).id
          let arry = {
            id: data[i].id,
            saleId: user_id,
            scoreSale: data[i].scoreSale *1,
            deductSale: data[i].deductSale,
          }
          list.push(arry)
        }
      }
      if (isSub) {
        this.spinningLive = true;
        const response = await updLiveScoreRecords(list)
        if (response.code === 200) {
          this.$message.success("修改成功~")
          // await this.getLiveList(this.liveId)
          await this.saveNext()
        }
        this.spinningLive = false;
      }
    },
    statisticsOpen(){
      this.visible_statistics = true
    },
    statistics_cancel(){
      this.visible_statistics = false
    },
    statistics_Ok(){
      this.visible_statistics = false
    },
    subtractStatistics(record){
      for (let i=0;i<this.dataStatistics.length;i++){
        if(record.id === this.dataStatistics[i].id && this.dataStatistics[i].TotalScore > 0){
          this.dataStatistics[i].TotalScore-- //场次
        }
      }
    },
    addStatistics(record){
      for (let i=0;i<this.dataStatistics.length;i++){
        if(record.id === this.dataStatistics[i].id){
          this.dataStatistics[i].TotalScore++ //场次
        }
      }
    },

  }
}
</script>

<style lang="scss" scoped>
.live-top{
  height: 80px;
  width: 100%;
  display: flex;
  border-bottom: 10px solid #f5f5f5;
  padding-top: 15px;
  .live-top-input{
    width: 25%;
    display: flex;
    height: 35px;
    line-height: 35px;
    padding-left: 30px;
    .input{
      width: 65%;
      margin-left: 5px;
    }
  }

}
.numberInput{
  display: flex;height: 30px;line-height: 30px;border: 1px solid #acadaf;width: 110px;
  .left{
    width: 30px;text-align: center;cursor: pointer;
  }
  .mid{
    width: 50px;
    .input{
      width: 50px;height: 27px;text-align: center;color: #45A5E6;
    }
  }
  .right{
    width: 30px;text-align: center;cursor: pointer;
    //background-color: #F0F2F5;
  }
}
</style>
